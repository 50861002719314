import React, { Component, Fragment } from 'react'
import { Redirect } from '@reach/router'

class Ofertas extends Component {
  render () {
    const url = typeof window !== 'undefined' && window.localStorage.getItem('currentMarket') ? `/lojas/${JSON.parse(window.localStorage.getItem('currentMarket')).slug}/` : 'lojas/'
    return (
      <Fragment>
        <div style={{
          display: 'block',
          height: 'calc(100vh - 100px)',
          clear: 'both'
        }}>
        </div>
        <Redirect to={url} noThrow/>
      </Fragment>
    )
  }
}

export default Ofertas
